<template>
  <sdCards class="security-card">
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">{{ i18n.t('security.openSessionsList') }}</sdHeading>
      </div>
    </template>
    <template v-slot:button>
      <sdButton size="small" class="close-sessions-button" type="danger" @click="closeAllSessions" outlined>
        {{ i18n.t('security.close', { msg: i18n.t('security.allSessions') }) }}
      </sdButton>
    </template>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <div>
          <TableWrapper class="table-data-view table-responsive" v-if="securityMeta">
            <a-table :pagination="false" :dataSource="dataSource" :columns="columns">
              <template #customTitleIp>
                <div style="display: flex; cursor: pointer" @click="sortTable('ip_address')">
                  <span>
                    {{ i18n.t('security.ipAddress') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'ip_address'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #customTitleCity>
                <div style="display: flex; cursor: pointer" @click="sortTable('city')">
                  <span>
                    {{ i18n.t('security.city') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'city'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #customTitleCountry>
                <div style="display: flex; cursor: pointer" @click="sortTable('country')">
                  <span>
                    {{ i18n.t('security.country') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'country'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
              <template #customTitleLogged>
                <div style="display: flex; cursor: pointer" @click="sortTable('created_at')">
                  <span>
                    {{ i18n.t('security.loggedAt') }}
                  </span>
                  <span style="margin-left: 10px">
                    <font-awesome-icon
                      :icon="faSort"
                      size="1x"
                      style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                      v-if="linkValue != 'created_at'"
                    />
                    <div v-else>
                      <font-awesome-icon
                        :icon="faSortUp"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-if="order == 'asc'"
                      />
                      <font-awesome-icon
                        :icon="faSortDown"
                        size="1x"
                        style="textshadow: '0 1px 0 rgba(0, 0, 0, 0.1)'"
                        v-else
                      />
                    </div>
                  </span>
                </div>
              </template>
            </a-table>
            <div style="text-align: right; margin-top: 20px">
              <a-pagination
                :default-page-size="pageSize"
                :show-size-changer="true"
                v-model:current="securityMeta.current"
                :total="securityMeta.count"
                @change="onChange"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </TableWrapper>
        </div>
      </a-col>
    </a-row>
  </sdCards>
</template>
<script>
import { RecordViewWrapper } from './style';
import { Main, TableWrapper } from '../../../styled';
import { useStore } from 'vuex';
import { computed, ref, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/routes/protectedRoute';
import { useI18n } from 'vue-i18n';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const route = useRoute();
    const { state, dispatch } = useStore();
    const security = computed(() => state.security.data);
    const securityMeta = computed(() => state.security.pagination);
    const i18n = useI18n();
    const pageNum = computed(() => route.params.pageNum);
    const linkValue = ref('id');
    const order = ref('desc');
    let pageSize = ref(10);

    const getableData = reactive({
      pageNum: pageNum.value,
      pageSize: pageSize.value,
      sortableLink: `&sort_by=${linkValue.value}&order=${order.value}`,
    });
    const sortTable = (value) => {
      if (linkValue.value == value) {
        order.value == 'asc' ? (order.value = 'desc') : (order.value = 'asc');
      }
      linkValue.value = value;
      getableData.sortableLink = `&sort_by=${value}&order=${order.value}`;
      dispatch('axiosSecurityGetData', getableData);
    };
    const onChange = (page) => {
      router.push('/profile/settings/security/page/' + page);
      getableData.pageNum = page;
      dispatch('axiosSecurityGetData', getableData);
    };
    onMounted(() => {
      dispatch('axiosSecurityGetData', getableData);
    });
    const closeSession = (id) => {
      dispatch('axiosSecurityCloseSession', id).then(() => {
        if (security.value.length < 1) {
          dispatch('auth/logout');
        }
      });
    };
    const closeAllSessions = () => {
      dispatch('axiosSecurityCloseAllSessions');
      dispatch('auth/logout');
    };
    const columns = computed(() => [
      {
        dataIndex: 'ip_address',
        key: 'ip_address',
        slots: { title: 'customTitleIp' },
      },
      {
        dataIndex: 'city',
        key: 'city',
        slots: { title: 'customTitleCity' },
      },
      {
        dataIndex: 'country',
        key: 'country',
        slots: { title: 'customTitleCountry' },
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        slots: { title: 'customTitleLogged' },
      },
      {
        title: i18n.t('security.user_agent'),
        dataIndex: 'agent',
        key: 'agent',
      },

      {
        title: i18n.t('security.change'),
        dataIndex: 'change',
        key: 'change',
      },
      {
        title: '',
        width: 0,
      },
    ]);
    const dataSource = computed(() =>
      security.value.length
        ? security.value.map((person, key) => {
            const { id, attributes } = person;
            const { ip_address, city, country, created_at, updated_at, agent } = attributes;
            return {
              key: key + 1,
              id,
              ip_address,
              city,
              country,
              agent,
              created_at: i18n.d(created_at, 'long'),
              updated_at: i18n.d(updated_at, 'long'),
              change: (
                <sdButton onClick={() => closeSession(id)} size="default" outlined type="danger">
                  {i18n.t('security.close')}
                </sdButton>
              ),
            };
          })
        : [],
    );

    return {
      security,
      faSort,
      faSortUp,
      faSortDown,
      securityMeta,
      sortTable,
      linkValue,
      order,
      i18n,
      pageSize,
      onChange,
      pageNum,
      dataSource,
      closeSession,
      closeAllSessions,
      columns,
    };
  },
};

export default ViewPage;
</script>
